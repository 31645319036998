import Nette from './netteForms.js';
import debounce from 'lodash/debounce';

const { forEach } = Array.prototype;

const oldValidateControl = Nette.validateControl;

Nette.validateControl = function(elem, rules, onlyCheck, value, emptyOptional) {
    const controlIsValid = oldValidateControl(elem, rules, onlyCheck, value, emptyOptional);

    if (controlIsValid) {
        Nette.removeError(elem);
    }

    if (!onlyCheck) {
        const validationEvent = document.createEvent('Event');
        validationEvent.initEvent(controlIsValid ? 'nette:valid' : 'nette:invalid', true, true);
        elem.dispatchEvent(validationEvent);
    }

    return controlIsValid;
}

// override form validation to include checkOnly flag
Nette.validateForm = function(sender, checkOnly = false) {
    var form = sender.form || sender,
        scope = false;

    let isValid = true;

    if (form['nette-submittedBy'] && form['nette-submittedBy'].getAttribute('formnovalidate') !== null) {
        var scopeArr = Nette.parseJSON(form['nette-submittedBy'].getAttribute('data-nette-validation-scope'));
        if (scopeArr.length) {
            scope = new RegExp('^(' + scopeArr.join('-|') + '-)');
        } else {
            return isValid;
        }
    }

    var radios = {}, i, elem;

    for (i = 0; i < form.elements.length; i++) {
        elem = form.elements[i];

        if (elem.tagName && !(elem.tagName.toLowerCase() in {input: 1, select: 1, textarea: 1, button: 1})) {
            continue;

        } else if (elem.type === 'radio') {
            if (radios[elem.name]) {
                continue;
            }
            radios[elem.name] = true;
        } else if (elem.type === 'hidden') {
            continue;
        }

        if ((scope && !elem.name.replace(/]\[|\[|]|$/g, '-').match(scope)) || Nette.isDisabled(elem)) {
            continue;
        }

        if (!Nette.validateControl(elem, null, checkOnly)) {
            isValid = false;
        }
    }

    // dispatch nette form event
    if (!checkOnly) {
        const validationEvent = document.createEvent('Event');
        validationEvent.initEvent(isValid ? 'nette:form:valid' : 'nette:form:invalid', true, true);
        form.dispatchEvent(validationEvent);
    }
    return isValid;
};


// custom extending initForm to include input events
const originalInitForm = Nette.initForm;

Nette.initForm = function(form) {

    originalInitForm(form);

    const validate = function(event) {
        Nette.validateControl(event.target);
        Nette.validateForm(form, true);
    }

    // add field validations
    let fields = form.querySelectorAll('input:not([type="submit"]), textarea, select')
        ::forEach(element => {
            if (element.getAttribute('data-nette-rules')) {
                let shouldValidate = false; // prevent onload validation
                let hasChanged = false; // blur validation requires change status
                let blurred = false; // keyup validation only after changing field
                element.addEventListener('change', event => {
                    hasChanged = true;
                    if (shouldValidate) {
                        validate(event);
                    }
                });
                element.addEventListener('focus', () => {
                    shouldValidate = true;
                    hasChanged = false;
                });
                element.addEventListener('blur', event => {
                    blurred = true;
                    hasChanged && validate(event);
                });
                element.addEventListener('keyup', debounce(event => {
                    blurred && validate(event);
                }, 200));
            }
        });
}

Nette.addError = function(element, message) {
    element.setAttribute('data-nette-error', message);
}

Nette.removeError = function(element) {
    element.removeAttribute('data-nette-error');
}

Nette.reinit = function() {
    Array.from(document.forms)
        .forEach(form => {
            if (Array.from(form.elements).some(element => element.dataset.netteRules)) {
                Nette.initForm(form)
            }
        })
}

export default Nette;