import $ from 'jquery';

const pluginName = 'showIf';
const $window = $(window);
const defaults = {
    field: null,
    value: '',
    remove: false,
    parent: null,
    detach: false, // detaches children when not visible
    condition: '==' // '==', '!='
};

export default class ShowIf {

    constructor(element, options) {
        this.scope = element;
        this.$scope = $(element);

        this.options = $.extend({}, defaults, options);

        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    init() {
        if (this.options.field === null) {
            return;
        }

        // jquery fails on {} in id attribute
        let field = document.getElementById(this.options.field.replace('#',''));

        if (!field) {
            field = $(`[name="${this.options.field}"]`);
        }

        this.$field = $(field);
        this.$field.on('change', ::this.render);

        if (!$.isArray(this.options.value)) {
            this.options.value = [this.options.value];
        }

        this.options.value = this.options.value.map(::this.formatValue);

        this.$buffer = null;
        this.$parent = null;

        this.render();

        return this;
    }

    render() {
        let resolution = false;
        let fieldValue = this.formatValue(this.$field.val());

        if (this.$field.attr('type') === 'checkbox') {
            fieldValue = this.$field.is(':checked') ? 'checked' : '';
        }

        if (this.$field.attr('type') === 'radio') {
            fieldValue = this.$field.filter(':checked').val();
        }

        switch (this.options.condition) {
            case '==':
                resolution = this.options.value.indexOf(fieldValue) > -1 ? true : false;
            break;

            case '!=':
                resolution = this.options.value.indexOf(fieldValue) > -1 ? false : true;
            break;

            default:
                throw new Error('ShowIf: Unknown condition');
        }

        if (resolution) {
            this.show();
        } else {
            this.hide();
        }
    }

    attach() {
        if (this.$buffer !== null) {
            this.$buffer.appendTo(this.$parent);
            this.$buffer = null;
            this.$scope.trigger('change:dom');
        }
        return this;
    }

    detach() {
        if (this.$buffer === null) {
            if (this.$parent === null) {
                this.$parent = this.$scope.wrap('<div>').parent();
            }
            this.$buffer = this.$parent.children().detach();
        }
        return this;
    }

    show() {
        if (this.options.detach) {
            this.attach();
        }
        this.$scope.show();
        return this;
    }

    hide() {
        this.$scope.hide();
        if (this.options.detach) {
            this.detach();
        }
        return this;
    }

    formatValue(value) {
        if (this.$field.attr('type') === 'checkbox') {
            if (value === 'true') {
                return 'checked';
            }
        }

        return value + '';
    }

}

$.fn[pluginName] = function(options) {
    return this.each(function () {
        if (!$.data(this, 'plugin-' + pluginName)) {

            // parse data-options
            const data = $(this).data();
            let options = {};
            let settings = '';

            if (pluginName in data) {
                settings = data[pluginName];
                // pass options
                if ($.type(settings) === 'object') {
                    options = settings;
                }

                // parse shortcut and create options
                if ($.type(settings) === 'string' && settings !== '') {
                    const params = data[pluginName].match(/(.*)(!=|==)(.*)/);
                    options.field = params[1];
                    options.condition = params[2];
                    options.value = params[3].split(',');
                }
            }

            $.each(defaults, function(key, value) {
                const option = pluginName + key.substr(0,1).toUpperCase() + key.substr(1);
                if (option in data) {
                    options[key] = data[option];
                }
            });

            $.data(this, 'plugin-' + pluginName, new ShowIf(this, options));
        }
    });
};

$.fn[pluginName].defaults = defaults;

// listen to change event and init self
$(document).on('change:dom', event => {
    $('[data-show-if]', event.target).showIf();
});
$('[data-show-if]').showIf();