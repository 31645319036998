// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/giant.trigger'
import './plugins/giant.autosubmit'
import './plugins/giant.show-if'

// other
import './plugins/flickity.ios13fix.js'

// nette
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { USE_NATIVE_SCROLL, CMS, IE, iOS, android } from './core/config'
import ScrollService from './services/Scroll'

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()

import Swup from 'swup'
import swupGtmPlugin from 'swup/plugins/swupGtmPlugin'

if (!(CMS || IE || iOS || android)) {
    let skipPopState = false
    EventBus.on('swup:custom:block', () => (skipPopState = true))

    const swup = new Swup({
        LINK_SELECTOR:
            'a[href^="' +
            window.location.origin +
            '"]:not([data-no-swup]):not([data-component]), a[href^="/"]:not([data-no-swup]):not([data-rel="news-modal"]):not([data-rel="job-modal"]):not([data-component]), a[href^="#"]:not([data-no-swup]):not([data-component])',
        elements: [
            '#swup',
            '#modals',
            '#department-symbols-container',
            '#cta',
            '#heading',
        ],
        debugMode: false,
        doScrollingRightAway: false,
        animationSelector:
            '.store-Group-title span, .store-List-item, .news-List-item, .article-Card--huge, .article-List-item',
        animateScroll: false,
        preload: true,
        cache: true,
        skipPopStateHandling: (event) => {
            if (skipPopState) {
                skipPopState = false
                return true
            }

            if (event?.state?.source === 'swup') {
                return false
            }

            return true
        },
        plugins: [swupGtmPlugin],
    })

    // scroll
    swup.on('willReplaceContent', (event) => {
        queryAll('[data-swup]').forEach((element) => {
            removeComponents(element)
        })
    })

    swup.on('contentReplaced', (event) => {
        queryAll('[data-swup]').forEach((element) => {
            loadComponents(element)
        })
        Nette.reinit()

        setTimeout(() => {
            const id = window.location.hash.replace('#', '')
            const anchor = document.getElementById(id)

            if (id && anchor) {
                const box = anchor.getBoundingClientRect()
                ScrollService.setPosition(0, box.top + ScrollService.getScroll().y)
            } else if (swup.transition.custom !== 'category-list') {
                ScrollService.setPosition(0, 0)
            }

            if ($.nette) {
                $.nette.load()
            }

            EventBus.emit('swup:contentreplaced')
            setTimeout(() => ScrollService.resize(), 0)
        })
    })
}

EventBus.on('modal:open', (data) => {
    if (data.id !== 'video-modal') {
        return
    }
    const video = document.getElementById('video-loop')
    if (video) {
        video.pause()
    }
})

EventBus.on('modal:close', (data) => {
    if (data.id !== 'video-modal') {
        return
    }
    const video = document.getElementById('video-loop')
    if (video) {
        video.play()
    }
})
